
import { defineComponent, computed, onMounted, ref, reactive } from "vue";
import { StickyComponent } from "@/assets/ts/components";
import { headerWidthFluid } from "@/core/helpers/config";
import { headerFixed, headerFixedOnMobile } from "@/core/helpers/config";
import { MenuComponent } from "@/assets/ts/components";
import DropdownFiscalYear from "@/components/valored/DropdownFiscalYear.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { translate } from "@/hooks/TranslateService";
import { ICompany, ICompanyYear } from "@/store/modules/valored/CompanyModule";
import bus from "vue3-eventbus";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
    breadcrumbs: Array,
  },
  components: {
    DropdownFiscalYear,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const isHeaderSticky = computed(() => {
      if (window.innerWidth > 768) {
        return headerFixed.value;
      } else {
        return headerFixedOnMobile.value;
      }
    });

    const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

    const optionYearlyPeriod = ref(
      computed(() => store.getters.getCompanyYearList as ICompanyYear)
    );

    const user_id = reactive(
      computed(() => {
        if (route.params.id) return route.params.id;
        else return false;
      })
    );

    const selectedUser = ref(computed(() => store.getters.getSelectedUser));

    const companyProfile = ref(
      computed(() => store.getters.getCompanyProfile as ICompany)
    );

    const companyName = ref(
      computed(() => {
        if (isAdmin.value && selectedUser.value) {
          return selectedUser.value.denom;
        } else return companyProfile.value.denom;
      })
    );

    const printReport = () => {
      bus.emit("reportPrint");
    };

    const printReportPlus = () => {
      window.print();
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      StickyComponent.reInitialization();
    });

    return {
      headerWidthFluid,
      isHeaderSticky,
      isAdmin,
      user_id,
      translate,
      optionYearlyPeriod,
      companyName,
      route,
      printReportPlus,
      printReport,
    };
  },
});
