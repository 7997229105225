
import { defineComponent, computed, reactive, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { translate } from "@/hooks/TranslateService";
import { IUser } from "@/store/modules/valored/UserModule";
import { ICompany } from "@/store/modules/valored/CompanyModule";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const { locale } = useI18n();
    const router = useRouter();
    const store = useStore();

    const isAdmin = computed(() => store.getters.isAdmin);

    locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "/media/flags/united-kingdom.svg",
        name: "English",
      },
      it: {
        flag: "/media/flags/italy.svg",
        name: "Italiano",
      },
    };

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(
          () => {
            localStorage.removeItem("to_year_admin");
            localStorage.removeItem("to_year");
            localStorage.removeItem("simulate_user");
            router.push({ name: "sign-in" });
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        );
    };

    const onSignOut = () => {
      signOut();
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      locale.value = lang;
      window.location.reload();
    };

    const currentLanguage = (lang) => {
      return locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[locale.value];
    });

    const accountInfo = reactive({
      userProfile: computed(() => store.getters.currentUser as IUser),
      companyProfile: computed(
        () => store.getters.getCompanyProfile as ICompany
      ),
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      translate,
      currentLangugeLocale,
      countries,
      onSignOut,
      ...toRefs(accountInfo),
      locale,
      isAdmin
    };
  },
});
