
import { computed, defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { translate } from "@/hooks/TranslateService";
import { ICompanyYear } from "@/store/modules/valored/CompanyModule";
import { Actions } from "@/store/enums/StoreEnums";
import bus from "vue3-eventbus";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "dropdown-fiscal-year",
  components: {},
  setup() {
    const { t } = useI18n();
    
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));
    
    const optionYearlyPeriod = ref(
      computed(() => store.getters.getCompanyYearList as ICompanyYear)
    );

    onMounted(() => {
      if (!localStorage.getItem("id_user")) {
        return;
      }
      
      if (isAdmin.value) {
        store.dispatch(Actions.REQ_COMPANY_LIST_YEAR, route.params.id);
      } else {
        store.dispatch(Actions.REQ_COMPANY_LIST_YEAR);
      }
    });

    const changeCurrentYearlyPeriod = async (year: number) => {
      console.log(year);
      
      localStorage.setItem(
        "from_year_modal",
        isAdmin.value ? localStorage.getItem("to_year_admin") as string : localStorage.getItem("to_year") as string
      );
      
      if (
        route.name === "admin-survey-plus-report" ||
        route.name === "AdminSurveyPlus" ||
        route.name === "survey-plus-report" ||
        route.name === "survey-plus"
      ) {
        if (year < 2021) {
          Swal.fire({
            text: t("SURVEY_PLUS_OLDER_THAN_2021"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-dark-blue",
            },
          });

          return;
        }
      }

      if (
        route.name === "admin-report" ||
        route.name === "report"
      ) {
        if (!optionYearlyPeriod.value.company_year_iii_is_completed[year]) {
          Swal.fire({
            text: t("REPORT_INCOMPLETED_YEAR_MESSAGE") + year + ".",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-dark-blue",
            },
          });
          
          return;
        }
      }

      if (isAdmin.value) {
        localStorage.setItem("to_year_admin", year.toString());
      } else {
        localStorage.setItem("to_year", year.toString());
      }
      
      store.dispatch(Actions.CHANGE_CURRENT_YEARLY_PERIOD, year);
      if (isAdmin.value) {
        store.dispatch(Actions.REQ_COMPANY_LIST_YEAR, route.params.id);
        store.dispatch(Actions.REQ_III_IS_PENDING, route.params.id);
        store.dispatch(Actions.REQ_COMPANY_PER_YEAR_PARAMETERS, route.params.id);
        store.dispatch(
          Actions.REQ_COMPANY_YEARLY_PARAMS, 
          JSON.parse(localStorage.getItem("simulate_user") as string).azienda_id
        ).then((data) => {
          bus.emit("onGetCompanyYearlyParams", data);
        });
      } else {
        store.dispatch(Actions.REQ_COMPANY_LIST_YEAR);
        store.dispatch(Actions.REQ_III_IS_PENDING);
        store.dispatch(Actions.REQ_COMPANY_PER_YEAR_PARAMETERS);
        store.dispatch(Actions.REQ_COMPANY_YEARLY_PARAMS).then((data) => {
          bus.emit("onGetCompanyYearlyParams", data);
        });
      }

      if (!optionYearlyPeriod.value.available_years.includes(year)) {
        store.dispatch(Actions.REQ_III, {
          user_id: route.params.id,
          year: year
        });

        if (isAdmin.value) {
          router.push({ name: "AdminUserDashboard" });
        } else {
          router.push({ name: "dashboard" });
        }

        Swal.fire({
          text: t("MISSING_YEARLY_PARAMETERS") + year + ".",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-dark-blue",
          },
        });
      } else {
        if (
          route.name === "AdminUserDashboard" ||
          route.name === "dashboard" ||
          route.name === "AdminSurvey" || 
          route.name === "survey-iii" ||
          route.name === "AdminSurveyPlus" ||
          route.name === "simulated-user-account-overview" ||
          route.name === "simulated-user-account-settings" ||
          route.name === "simulated-user-company-settings" ||
          route.name === "survey-plus" || 
          route.name === "admin-report" ||
          route.name === "report" ||
          route.name === "survey-plus-report" ||
          route.name === "survey-plus" ||
          route.name === "account-overview" ||
          route.name === "company-settings" ||
          route.name === "account-settings"
        ) {
          window.location.reload();
        }
      }
    };

    return {
      translate,
      optionYearlyPeriod,
      changeCurrentYearlyPeriod,
    };
  },
});
