
import { computed, defineComponent, reactive, ref } from "vue";
import { translate } from "@/hooks/TranslateService";
import KTUserMenu from "@/layout/header_valored/partials/UserMenu.vue";
import KTSimulatedUserMenu from "@/layout/header_valored/partials/SimulatedUserMenu.vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ICompanyYear } from "@/store/modules/valored/CompanyModule";

export default defineComponent({
  name: "kt-aside-primary",
  components: {
    KTUserMenu,
    KTSimulatedUserMenu
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();

    const isAdmin = reactive(computed(() => store.getters.isAdmin));

    const optionYearlyPeriod = ref(
      computed(() => store.getters.getCompanyYearList as ICompanyYear)
    );

    const user_id = reactive(
      computed(() => {
        if (route.params.id) return route.params.id;
        else return false;
      })
    );

    const onClickSidebarLink = (target: any) => {
      const sidebarDashboardLink = document.getElementById("sidebar-dashboard-link");
      const sidebarAccountLink   = document.getElementById("sidebar-account-link");
      if (target === "dashboard") {
        sidebarAccountLink?.classList.remove("active-link");
        sidebarDashboardLink?.classList.add("active-link");
      } else if (target === "account") {
        sidebarDashboardLink?.classList.remove("active-link");
        sidebarAccountLink?.classList.add("active-link");
      }
    };

    function getDashboardLinkClass() {
      if (route.path.toLowerCase().includes("dashboard")) {
        return "active btn-sidebar nav-link btn btn-active-color-primary btn-active-light text-center";
      } else {
        return "btn-sidebar nav-link btn btn-active-color-primary btn-active-light text-center";
      }
    }

    function getAccountLinkClass() {
      if (route.path.toLowerCase().includes("account")) {
        return "active btn-sidebar nav-link btn btn-active-color-primary btn-active-light text-center";
      } else {
        return "btn-sidebar nav-link btn btn-active-color-primary btn-active-light text-center";
      }
    }

    return {
      isAdmin,
      t,
      translate,
      user_id,
      optionYearlyPeriod,
      route,
      onClickSidebarLink,
      getDashboardLinkClass,
      getAccountLinkClass,
    };
  },
});
